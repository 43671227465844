<script lang="ts" setup>
export interface RadioCardOption {
  icon?: string;
  label: string;
  description?: string;
}

defineProps<{
  options: RadioCardOption[];
}>();

const selected = defineModel<RadioCardOption>();

const { inputId, emitFormChange, emitFormInput, name } = useFormGroup();

function handleClick(option: RadioCardOption) {
  selected.value = option;
  emitFormChange();
  emitFormInput();
}
</script>

<template>
  <div
    :id="inputId"
    :name="name"
    class="flex gap-4 pt-1"
  >
    <button
      v-for="option in options"
      :key="option.label"
      class="flex w-full flex-col items-center gap-2 rounded border p-3 transition xs:p-4"
      :class="selected?.label === option.label
        ? 'bg-primary-100/75 border-primary-300 dark:bg-primary-950 dark:border-primary-700'
        : 'border-gray-300 dark:border-gray-700 dark:hover:bg-gray-950/50 dark:hover:border-gray-700 hover:bg-gray-100 hover:border-gray-300'"
      @click.prevent="handleClick(option)"
    >
      <UIcon
        v-if="option.icon"
        :name="option.icon"
        class="size-8"
      />
      <div class="flex flex-col gap-1">
        <span class="text-sm font-semibold">{{ option.label }}</span>
        <span
          v-if="option.description"
          class="text-xs"
        >{{ option.description }}</span>
      </div>
    </button>
  </div>
</template>
