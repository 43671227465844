<script setup lang="ts">
const avatarUrl = computed(() => useUserStore().info?.avatarUrl);
const firstName = computed(() => useUserStore().info?.name.split(" ")[0]);
const now = useNow();

const { canGoToPath } = useAuthorizations();
const { missiveNavItems } = useNavigation();

const navItems = computed(() => {
  const permitted = missiveNavItems.filter(item => canGoToPath(item.to));
  const formatted = permitted.map((item) => {
    const current = useRoute().path;

    return {
      ...item,
      active: current.includes(item.to),
    };
  });

  return formatted;
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <!-- Welcome Message -->
      <div class="flex items-start gap-2">
        <div
          class="flex items-center justify-center rounded-full"
          :class="[avatarUrl?.includes('dicebear') ? 'bg-primary-200' : 'border-primary-300 border-2 p-0.5']"
        >
          <UAvatar
            :src="avatarUrl"
            alt="Avatar"
            size="sm"
          />
        </div>
        <div class="flex flex-col">
          <GText
            size="none"
            class="text-sm font-bold xs:text-base"
          >
            Hi {{ firstName }}!
          </GText>
          <GText
            size="none"
            variant="secondary"
            class="text-xs xs:text-sm"
          >
            {{ now.toLocaleString('en-US', { timeZone: 'America/New_York', hour: 'numeric', minute: 'numeric' }) }} (NY)
          </GText>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="flex items-start gap-0">
        <div
          v-for="(item, idx) in navItems"
          :key="idx"
        >
          <UTooltip :text="item.label">
            <UButton
              size="xs"
              :variant="item.active ? 'solid' : 'soft'"
              :icon="item.icon"
              class="border-primary-300"
              :class="[idx === 0 ? 'border-y border-l' : idx === navItems.length - 1 ? 'border-y border-r' : 'border-y']"
              :ui="{
                rounded: idx === 0 ? 'rounded-r-none' : idx === navItems.length - 1 ? 'rounded-l-none' : 'rounded-none',
              }"
              @click.prevent="navigateTo(item.to)"
            />
          </UTooltip>
        </div>
      </div>
    </div>

    <!-- Grape Radio -->
    <div class="mt-1 pl-10">
      <UAccordion
        open-icon=""
        :items="[{
          label: 'Radio Grape',
          icon: 'i-heroicons-radio-20-solid',
          size: '2xs',
          variant: 'link',
          padded: false,
        }]"
      >
        <template #default="{ item, open }">
          <UButton
            v-bind="item"
          >
            <template #leading>
              <UIcon :name="item.icon" />
            </template>

            <template #trailing>
              <UIcon
                v-if="open"
                name="i-heroicons-chevron-up"
                class="ms-auto size-5 transform transition-transform duration-200"
              />
            </template>
          </UButton>
        </template>

        <template #item="">
          <iframe
            :src="useRuntimeConfig().public.grapeRadioUrl"
            class="flex h-[28rem] w-full overflow-hidden rounded"
            frameborder="0"
          />
        </template>
      </UAccordion>
    </div>
  </div>
</template>
