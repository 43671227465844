<script lang="ts" setup>
import confetti from "canvas-confetti";
import type { RadioCardOption } from "../G/Input/RadioCard.vue";
import type { HelpCreateTicketReqBody } from "~/server/api/help/createTicket.post";

const toast = useToast();
const { info } = storeToRefs(useUserStore());

const open = ref(false);

watch(open, () => {
  resetForm();
});

const typeOptions: RadioCardOption[] = [
  {
    icon: "i-heroicons-exclamation-triangle",
    label: "Issue",
    description: "Something isn't working as expected",
  },
  {
    icon: "i-heroicons-clipboard-document-list",
    label: "Need",
    description: "I need help with something",
  },
  {
    icon: "i-heroicons-light-bulb",
    label: "Idea",
    description: "I have an idea for something new",
  },
];

const topicOptions = ["PC & Devices", "Software & Tools", "Email & Missive", "Phone & Communications", "Authorization & Access", "New Automation", "Notion", "Grape App", "Grape Bot", "HR", "Onedrive", "Security"];
topicOptions.sort((a, b) => a.localeCompare(b));
topicOptions.push("Other");

const urgencyOptions = [
  { label: "Critical - I cannot perform my job functions.", value: "1 hour" },
  { label: "High - My work is significantly impacted.", value: "4 hours" },
  { label: "Medium - Needs to be done but not urgent.", value: "1 day" },
  { label: "Low - Would be nice to have.", value: "3 days" },
];

const type = ref<RadioCardOption>();
const topic = ref<string>("");
const message = ref<string>("");
const urgency = ref<string>("");

const pending = ref(false);

async function handleSubmit() {
  pending.value = true;

  try {
    const name = useUserStore().info?.name;
    const email = useUserStore().info?.email;

    if (!name || !email)
      throw createError("Please refresh the app to login before submitting a help ticket.");

    if (!type.value || !topic.value || !message.value || !urgency.value)
      throw createError("Please fill out the form before submitting.");

    await $fetch("/api/help/createTicket", {
      method: "POST",
      body: {
        name: name || "",
        email: email || "",
        department: info?.value?.department || "",
        type: type.value?.label,
        topic: topic.value,
        message: message.value,
        urgency: urgency.value,
        responseTime: urgencyOptions.find(f => f.label === urgency.value)?.value || "",
      } satisfies HelpCreateTicketReqBody,
    });
    open.value = false;
    toast.add({
      title: "We've got your help ticket!",
      description: `We'll get back to you within ${urgencyOptions.find(f => f.label === urgency.value)?.value} at the latest.`,
      icon: "i-heroicons-check-circle-solid",
    });
    throwConfetti();
  }
  catch (error: any) {
    toast.add({
      title: "Something went wrong",
      description: error.message || "Please try again.",
      icon: "i-heroicons-exclamation-triangle",
      color: "red",
    });
  }

  pending.value = false;
}

function resetForm() {
  type.value = undefined;
  topic.value = "";
  message.value = "";
  urgency.value = "";
}

function throwConfetti() {
  confetti({
    particleCount: 100,
    spread: 80,
    origin: { y: 0.6 },
  });
}
</script>

<template>
  <div>
    <UButton
      icon="i-heroicons-question-mark-circle-solid"
      size="xl"
      class="scale-100 rounded-full p-0.5 transition-transform hover:scale-110"
      variant="outline"
      :ui="{
        icon: {
          size: {
            xl: 'size-8',
          },
        },
      }"
      @click.prevent="open = true"
    />

    <UDashboardModal
      v-model="open"
      title="Let's get you help"
      description="Fill out the form below in English or Turkish, and we'll get back to you."
      :prevent-close="!!type"
    >
      <div
        v-auto-animate
        class="space-y-3"
        :class="{
          'pointer-events-none opacity-60': pending,
        }"
      >
        <GInputRadioCard
          v-model="type"
          :options="typeOptions"
        />

        <USelectMenu
          v-if="!!type"
          v-model="topic"
          :options="topicOptions"
          searchable
          clear-search-on-close
        >
          <template #label>
            <span
              v-if="topic"
              class="truncate"
            >
              {{ topic }}
            </span>
            <span v-else>
              Select topic
            </span>
          </template>
        </USelectMenu>

        <UTextarea
          v-if="!!type && !!topic"
          v-model="message"
          :placeholder="`Provide details on your ${type.label !== 'Other' ? type.label.toLowerCase() : 'request'}`"
          autoresize
          :rows="5"
          :maxrows="10"
        />

        <div
          v-if="!!type && !!topic && !!message"
          v-auto-animate
        >
          <USelectMenu
            v-model="urgency"
            :options="urgencyOptions.map(f => f.label)"
            placeholder="How urgent is this?"
          />
          <GText
            v-if="!!urgency"
            size="xs"
            class="pt-1"
          >
            You will get a response within <b>{{ urgencyOptions.find(f => f.label === urgency)?.value }}</b> at the latest.
          </GText>
        </div>

        <div class="pt-2">
          <UButton
            v-if="!!type && !!topic && !!message && !!urgency"
            class="font-semibold"
            label="Submit"
            trailing-icon="i-heroicons-paper-airplane"
            :loading="pending"
            block
            @click.prevent="handleSubmit"
          />

          <UButton
            class="mt-2"
            label="Cancel"
            variant="link"
            size="xs"
            block
            @click.prevent="open = false"
          />
        </div>
      </div>
    </UDashboardModal>
  </div>
</template>
